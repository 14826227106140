
import React from "react"

import OnsorderPage from "../../components/negosyonow/controls/onsordereditor";

const PickupOrderPage = ({location}) => {
	return <OnsorderPage
				location={location}
				methodid={3}
			/>
}


export default PickupOrderPage;
